$primary-color: white;
@import '~loaders.css/src/animations/ball-pulse.scss';

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}
.loader-inner {
  transform: scale(0.55, 0.55);
}

.react-reveal {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.react-datepicker__input-container>input {
  border-radius: 5px;
  outline: none;
  text-align: center;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.color__black div {
  background-color: black;
}

.MuiTableRow-root {
  cursor: pointer;
  user-select: none;
}

.MuiTableRow-root {
  z-index: 0;
}

td[class^='TableDetailCell-active'], td[class*='TableDetailCell-active']{
  background-color: white;
}

span[class ^='Title-title'] {
  font-weight: bold;
}